/* navbar */
.nav li a {
    position: relative;
    display: block;
    padding: 10px 5px;
}

.navbar-default {
    background-color: rgba(255,255,255,0.8);
    border-color: transparent;
    margin-bottom: 0px;
    position: fixed;
    width: 100%;
    border-radius: 0px;
    z-index: 10007;
}


.footer {
     background: #4D4D4D;
     padding: 40px 100px;
     color:#ffffff;
}

body.home .navbar-default{
    background-color: #ffffff;
}

.content-block-one {
    padding: 30px;
    margin-bottom: 0px;
    color: #ffffff;
    background-color: #039BAD;
}

.content-block-two {
    padding: 30px;
    margin-bottom: 0px;
    color: #ffffff;
    background-color: #0064AD;
}

.content-block-one h1,
.content-block-one .h1 {
    color: inherit;
}

.content-block-two h1,
.content-block-two .h1 {
    color: inherit;
}

.content-block-one p {
    margin-bottom: 15px;
    font-size: 30px;
    font-weight: 200;
}

.content-block-two p {
    margin-bottom: 15px;
    font-size: 30px;
    font-weight: 200;
}

.content-block-one > hr {
    border-top-color: #d5d5d5;
}

.content-block-two > hr {
    border-top-color: #0064AD;
}

.container .content-block-one {
    border-radius: 6px;
}

.content-block-one .container {
    max-width: 100%;
}

.content-block-two .container {
    max-width: 100%;
}

@media screen and (min-width: 768px) {
    .content-block-one {
        padding-top: 120px;
        padding-bottom: 120px;
    }
    .container .content-block-one {
        padding-right: 60px;
        padding-left: 60px;
    }
    .content-block-one h1,
    .content-block-one .h1 {
        font-size: 63px;
    }

    .content-block-two {
        padding-top: 5%;
        padding-bottom: 120px;
    }
    .container .content-block-two {
        padding-right: 60px;
        padding-left: 60px;
    }
    .content-block-two h1,
    .content-block-two .h1 {
        font-size: 63px;
    }


}

.content-space-one {

    margin-bottom: 0px;
    color: inherit;
    background: url('../../img/header-10-aniv.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 16%;
}
.content-space-two {
    padding: 0px;
    margin-bottom: 0px;
    color: inherit;
    background: url('../../img/Mineria/mineria.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;

}
@media (max-width:768px ) {
    .navbar-header{
        margin-bottom: 22px;
    }
}
.page-break {
    width: 50%;
    height: 3px;
    background-color: #fff;
    margin: 0 auto;
}

.imgFooter{
    padding-left: 0px;
    padding-bottom: 1px;
}

.contact-form input:not([type="submit"]), .contact-form textarea {
    border: 2px solid #7D7D7D;
    display: block;
    height: 40px;
    padding: 5px 20px;
    text-align: left;
    width: 100%;
    color: #000;
    font-size: 13px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.15em;
    background: #fff;
    margin-top: 12px;
}

.contact-form-footer input:not([type="submit"]), .contact-form-footer textarea {
    border: 2px solid #fff;
    display: block;
    height: 40px;
    padding: 5px 20px;
    text-align: left;
    width: 100%;
    color: #ffffff;
    font-size: 13px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.15em;
    background: #4D4D4D;
    margin-top: 12px;
}
.btn-form{

    padding:10px 45px;
    color:#fff;
    font-family:'Helvetica',sans-serif;
    font-size:15px;
    border-radius:0;
    -moz-border-radius:0;
    -webkit-border-radius:0;
    border:1px solid #1A356E;

    background-color: #039BAD;
    border-color: #039BAD;
}
.logo-footer{
    width: 100%;
    margin-top: 25px;
    margin-left: 0px;
}

.logo_1{
    width: 200px;
    margin: 15px;
}

ul {
    background-color: rgba(255,255,255,0.1);
    border-bottom: 0px;
    padding: 0px;
}

ul a {
    background-color: rgba(255,255,255,0.1);
}

.content-block-white {
    padding: 30px;
    margin-bottom: 0px;
    color: #000;
    background-color: #fff;
}

.content-block-white-adv {
    padding: 30px;
    margin-bottom: 0px;
    color: #000;
    background-color: #fff;
}
.content-block-white-product {

    color: #000;
    background-color: #fff;
}
.content-block-img {
    margin-bottom: 0px;
    color: #000;
    background-color: #fff;
}

.content-block-esme {
    padding: 30px;
    margin-bottom: 0px;
    color: #ffffff;
    background-color: #039BAD;
}

.content-block-blue {
    padding: 30px;
    margin-bottom: 0px;
    color: #ffffff;
    background-color: #0064AD;
}

.content-block-blue-product {
    padding: 30px;
    color: #ffffff;
    background-color: #0064AD;
}

.content-block-blue-product h2 {
    font-weight: 200;
    font-size: 26px;
}

.content-block-blue-product ul {
    list-style-type: circle;
    padding: 0px;
    background-color:#0064AD;
    color: #fff;
    list-style: circle;
    text-align: left;

}
.content-block-blue-product li {
    font-size: 24px;
    font-weight: 100;
}

.content-block-white-product ul {
    background-color: #fff;
    text-align: left;
    color: #000000;
}
.content-block-white-product a {
    color: #000000;
    font-size: 25px;
    font-weight: 200;
}

.content-block-blue-serv {
    margin-bottom: 0px;
    color: #ffffff;
    background-color: #0064AD;
}

.content-block-white h1,
.content-block-white .h1 {
    color: inherit;
}

.content-block-esme h1,
.content-block-esme .h1 {
    color: inherit;
}

.content-block-white p {
    margin-bottom: 15px;
    font-size: 26px;
    font-weight: 100;
}

.content-block-white-product p {
    margin-bottom: 15px;
    font-size: 26px;
    font-weight: 100;
}

.content-block-blue p {
    margin-bottom: 15px;
    font-size: 26px;
    font-weight: 100;
}


.content-block-esme p {
    margin-bottom: 15px;
    font-size: 26px;
    font-weight: 100;
}

.content-block-white > hr {
    border-top-color: #ffffff;
}

.content-block-esme > hr {
    border-top-color: #0064AD;
}

.container .content-block-white {
    border-radius: 6px;
}

.content-block-white .container {
    max-width: 100%;
}

.content-block-esme .container {
    max-width: 100%;
}

.content-block-blue .container {
    max-width: 100%;
}

@media ( max-width:768px ){

    .content-block-white p {
        font-size: 18px;
    }

    .content-block-white-product p{
        font-size: 18px;
    }

    .content-block-white-adv p{
        font-size: 18px;
    }

    .content-block-white h1,
    .content-block-white .h1 {
        font-size: 45px;
    }

    .content-block-esme p{
        font-size: 18px;
    }

    .content-block-esme h1,
    .content-block-esme .h1 {
        font-size: 45px;
    }
    .direccion p{
        font-size: 16px;
    }
}

@media screen and (min-width: 768px) {
    .content-block-white {
        padding-top: 120px;
        padding-bottom: 120px;
    }

    .content-block-white-product {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .content-block-white-adv {
        padding-top: 45px;
        padding-bottom: 70px;
    }

    .container .content-block-white {
        padding-right: 60px;
        padding-left: 60px;
    }
    .content-block-white h1,
    .content-block-white .h1 {
        font-size: 63px;
    }

    .content-block-esme {
        padding-top: 120px;
        padding-bottom: 120px;
    }

    .container .content-block-esme {
        padding-right: 60px;
        padding-left: 60px;
    }
    .content-block-esme h1,
    .content-block-esme .h1 {
        font-size: 63px;
    }

    .content-block-img {
        padding-top: 20px;
        padding-bottom: 35%;
    }
    .content-block-blue p {
        font-size: 18px;
    }

}

@media (max-width: 1200px){
    .navbar-right {
        margin-top: 8px!important;
        padding-bottom: 5px!important;
    }
}

@media ( max-width: 992px ){
   .navbar-toggle {
        margin-top: 30px;
        margin-bottom: 30px;
    }
    .footer {

        padding: 40px 50px;

    }
}

.content-space-header-about {

    margin-bottom: 0px;
    color: inherit;
    background: url('../../img/header_about.jpg') no-repeat center top fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 2%;
}
.content-space-two-about {
    padding: 0px;
    margin-bottom: 0px;
    color: inherit;
    background: url('../../img/nosotros4.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;

}

.content-space-three-about {
    padding: 0px;
    margin-bottom: 0px;
    color: inherit;
    background: url('../../img/nosotros4.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;

}

.content-space-video {
    padding: 0px;
    margin-bottom: 0px;
    color: inherit;
    background: url('../../img/nosotros2.jpg') no-repeat;

}


.titlePage{
    color: #fff;
    font-size: 45px;
    text-transform: uppercase;
    position: absolute;
    z-index: 999;
    margin-top: -115px;
    margin-left: 60px;
}

/*********** home *************************/

#pinContainer {
    width: 100%;
    height: 722px;
    background-color: #0064AD;
    overflow: hidden;
    -webkit-perspective: 1000;
    perspective: 1000;
}
#slideContainer {
    width: 400%; /* to contain 4 panels, each with 100% of window width */
    height: 100%;
}
.panel {
    height: 50%;
    width: 25%; /* relative to parent -> 25% of 400% = 100% of window width */
    float: left;
    background-color: #0064AD;
}
.panel img{ width: 100%;}

/*********** Products ***********************/

.content-space-header-products {

    margin-bottom: 0px;
    color: inherit;
    background: url('../../img/header_products.jpg') no-repeat center top fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 2%;
}

.content-space-header-advantage {

    margin-bottom: 0px;
    color: inherit;
    background: url('../../img/header_advantages.jpg') no-repeat center top fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 2%;
}

.content-space-header-works {

    margin-bottom: 0px;
    color: inherit;
    background: url('../../img/header_works.jpg') no-repeat center top fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: 100%;
    padding-top: 2%;
}

.titleBlue{

    color: #0064AD;
    font-weight: 800;
    font-size: 50px;
    text-transform: uppercase;
}

.subtitleBlue{

    color: #0064AD;
    font-weight: 300;
    font-size: 25px;
    text-transform: uppercase;
}

.content-block-white-adv ul {
    text-align: left;
    font-size: 20px;
    font-weight: 200;
}
.content-block-white-adv li:before{
    color: #039BAD;
    content: "•";
    font-size: 170%;
    padding-right: 5px;
}

.content-space-header-service {

    margin-bottom: 0px;
    color: inherit;
    background: url('../../img/servicio.jpg') no-repeat center top fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: 100%;
    padding-top: 2%;
}
.textBlue{
    color: #0064AD;
}
.bgWhite{
    background-color: rgba(255,255,255,0.7);
    padding-top: 10%;
    padding-bottom: 10%;
}

.imgBg{
    margin-bottom: 0px;
    color: inherit;
    background: url('../../img/reticulafondo.png') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: 100%;

}
.button-group button{
    background-color: #fff;
    border: none;
    color: #0064AD;
    font-size: 24px;
    padding: 10px 25px;
}


/******  products   *********/


.flip {
    height: 300px;
    width: 300px;
    margin : 20px auto;
}
.flip img {
    width: 100%;
    height: auto;
}
.flip .back {
    background: #2184cd;
    color: #fff;
    text-align: center;
}


/********  works   *************/

.grid {
    background: #fff;
    max-width: 100%;
}

/* clearfix */
.grid:after {
    content: '';
    display: block;
    clear: both;
}

/*********** contact  *******************/


.content-space-header-contact {

    margin-bottom: 0px;
    color: inherit;
    background: url('../../img/header_contact.jpg') no-repeat center top fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 2%;
}

.google-maps {
    position: relative;
    padding-bottom: 40%;
    height: 0;
    overflow: hidden;
}
.google-maps #gmap_canvas {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
}
.direccion{
    position: absolute;
    width: 100%;
    font-size: 26px;
    color: #fff;
    background-color: rgba(0, 100, 173,0.6);
    padding-bottom: 100%;
}
.direccion p{
    font-weight: 200;
    margin: 20% 60px;
}

@media (max-width: 767px){
   .direccion p{
       margin: 20px;
   }
}

@media (max-width: 1200px){
    .navbar-nav {
        margin-top: -25px!important;
        padding-bottom: 0px!important;
    }
    .navbar-right{
        margin-top: 0px!important;
        padding-bottom: 0px!important;
        margin-right: -32px;
    }
    .nav li a {
        position: relative;
        display: block;
        padding: 10px 2px;
        font-size: 13px;
    }

}

@media (max-width: 768px){
    .nav li a {
        padding: 14px 5px;
    }

}
footer a {
    color: #ffffff;
}
footer a:hover {
    color: #039BAD;
}
