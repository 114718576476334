@media (max-width: @screen-xs-max) {
	.content-space-one{
		background-position: center center;
		background-size: auto 100%;
		background-attachment: inherit;
	}
	.nav li a{
		padding: 8px 5px;
	}
}
.side-margins{
	margin-left: 50px;
	margin-right: 50px;
	@media (max-width: @screen-xs-max) {
		margin-left: 0;
		margin-right: 0;
	}
}

.side-margins-lg{
	margin-left: 100px;
	margin-right: 100px;
	@media (max-width: @screen-xs-max) {
		margin-left: 15px;
		margin-right: 15px;
	}
}

.margins{
	margin: 50px;
	@media (max-width: @screen-xs-max) {
		margin: 15px;
	}
}

.margins-lg{
	margin: 100px;
	@media (max-width: @screen-xs-max) {
		margin: 15px;
	}
}