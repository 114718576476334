/* ---- grid-item ---- */
.grid-sizer,
.grid-item {
    width: 33%;
    @media (max-width: @screen-sm-max) {
  		width: 50%;
  	}

    @media (max-width: @screen-xs-max) {
      width: 100%;
    }
}

.grid-item {
    float: left;
    background: #666;
    border: 2px solid #fff;
    border-color: hsla(100%, 100%, 100%, 0.7);
    margin-bottom: 5px;
}

.grid-item--width2 {
	width: 66%;

  @media (max-width: @screen-xs-max) {
    width: 100%;
  }
}

.content-space-header-about,
.content-space-header-products,
.content-space-header-advantage,
.content-space-header-works,
.content-space-header-service {
  @media (max-width: @screen-xs-max) {
    background-size: cover;
  }
}

@media (max-width: @screen-xs-max) {
  body {
    padding-top: 118px;
  }

  #menu {
    top: 0;
  }
}

.content-block-white-product {
  ul {

    li {
      padding-top: 10px;
      padding-bottom: 10px;

      a {
        display: inline;
      }
    }
  }
}

.secondLogo{
    position: absolute;
    width: 170px;
    margin-top: 10px;
    display: inline;
    margin-left: -20px;
    @media (max-width: @screen-xs-max) {
        width:120px;
    }
}

.nav.navbar-nav{
    a:hover{
            color: #0064AD !important;
    }
    a.current{
        color: #0064AD !important;
        font-weight: 500;
    }
}

a .linkFooter{
    color: #fff;
    font-size: 12px;
    &:hover{
        color: #fff!important;
    }
}

.infoNav{

    background: #0064AD;
    margin: -1px -16px;
    padding-top: 10px;
    padding-bottom: 1px;
    padding-right: 3%;
    color: #fff;
    font-size: 13px;

    a{
        padding-right: 20px;
        color: #fff!important;
    }

    a:hover{
        color: #fff!important;
    }

    img{
        padding-left: 20px;
        margin-top: -5px;
    }
}
.imgProduct{
    width: 25% !important;
}



iframe[name='google_conversion_frame'] {
    height: 0 !important;
    width: 0 !important;
    line-height: 0 !important;
    font-size: 0 !important;
    margin-top: -13px;
    float: left;
}

@media (max-width: @screen-xs-max) {
  .titlePage {
      font-size: 28px;
      margin-left: 20px;
  }

  .navbar-header {
    margin-bottom: 0;
  }
}

@media (max-width: 380px) {
    .navbar-default .navbar-brand {
        width: 80% !important;
    }

    .titlePage {
        margin-top: -90px;
    }

    img.secondLogo{
        width: 25%;
    }
    p {
        font-size: 20px !important;
    }
}
