.about .thumbnail{
  background-color: #2184cd;
  text-align: center;

  .caption {
    color: #fff;

    > a {
      display: block;
      color: #fff;

      &:hover {
        text-decoration: none;
      }
    }

    .btn-primary {
      background-color: #2184cd;
      border-color: #ccc;

      &:hover {
        color: #fff;
        border-color: #fff;
      }
    }
  }
}
