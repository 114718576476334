/* ---- grid-item ---- */
.grid-sizer,
.grid-item {
    @media (min-width: @screen-sm-min) { 
        width: 100%;
     }

    
    @media (min-width: @screen-md-min) { 
        width: 50%;
    }

    
    @media (min-width: @screen-lg-min) { 
        width: 30%;
    }

}

.grid-item {
    float: left;
    background: #666;
    border: 2px solid #fff;
    border-color: hsla(100%, 100%, 100%, 0.7);
    margin-bottom: 5px;
}

.grid-item-width2 { 
    @media (min-width: @screen-sm-min) { 
        width: 100%;
     }

    
    @media (min-width: @screen-md-min) { 
        width: 50%;
    }

    
    @media (min-width: @screen-lg-min) { 
        width: 40%;
    }
}